"use client"

import { CustomErrorPage } from "@/app/components/ui/Error/CustomErrorPage"

export default function Error({
  error,
  reset,
}: {
  error: Error & { digest?: string }
  reset: () => void
}) {
  return <CustomErrorPage error={error} onReset={reset} />
}

// Handles errors in route segments and their children, but not in the root layout.